export const photos = [
    {
      src: "images/event/img1.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "images/event/img2.jpeg",
      width: 1,
      height: 1
    },
    {
      src: "images/event/img3.jpeg",
      width: 3,
      height: 4
    },
    {
      src: "images/event/img4.jpeg",
      width: 6,
      height: 6
    },
    {
      src: "images/event/img5.jpeg",
      width: 6,
      height: 6
    },
    {
      src: "images/event/img6.jpeg",
      width: 6,
      height: 6
    },

 
  ];
  